<template>
  <div class="full-area">
    <QuestionHeader
      :data="data"
      :no-question-title="true"
    ></QuestionHeader>

    <h2
      class="optional-question-title"
      v-if="data.fields.questionTitle || data.fields.title"
    >{{data.fields.questionTitle}}</h2>

    <content class="content delay-entry">
      <div class="grid-wrapper">
        <div
          :span="12"
          v-for="option in randomizedOptions"
          :key="option"
          class="binary-box trinary-box"
          :class="{'selected-box': form.selected === option}"
          @click="selectThis(option)"
        >
          <div
            class="center-me"
            :class="`card_thumbs-${option}`"
          >
            <Icons class="icon" :icon="option" size="70" color="#5d2466"></Icons>
          </div>
        </div>
      </div>
    </content>
    <footer>
      <SkipQuestionButton
        :location="'locate-bottom'"
        v-on:skip="selectThis('S99')"
      ></SkipQuestionButton>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Emoji',

  props: [ 'data' ],

  components: {
    Icons: () => import('Components/base/Icons'),
    QuestionHeader: () => import('Components/base/QuestionHeader'),
    SkipQuestionButton: () => import('Components/base/SkipQuestionButton')
  },

  data () {
    return {
      locked: false,
      options: [ 'emoji-heart', 'emoji-yummy', 'emoji-cool', 'emoji-hug', 'emoji-dizzy', 'emoji-grimace' ],
      form: {
        type: `emoji`,
        identifier: this.data.fields.identifier,
        question: this.data.fields.title,
        numberOfOptions: 6,
        options: [ 'emoji-heart', 'emoji-yummy', 'emoji-cool', 'emoji-hug', 'emoji-dizzy', 'emoji-grimace' ],
        id: this.data.sys.id,
        selected: [],
        timeToCompletion: null,
        timestamps: {
          start: new Date(),
          finish: null,
          valid: null
        }
      }
    }
  },

  computed: {
    randomizedOptions () {
      const options = this.options
      return options.sort(function () { return 0.5 - Math.random() })
    }
  },

  methods: {
    selectThis (item) {
      if (this.locked) {
        return
      }
      this.form.selected = [item]
    },
    next () {
      this.form.timestamps.finish = new Date()
      this.locked = true
      this.$emit('next', this.form)
    },
    validInput (value) {
      // Add simple validation: if it exists, it is valid.
      if (value) {
        this.form.timestamps.valid = new Date()
        this.form.timeToCompletion = this.form.timestamps.valid.getTime() - this.form.timestamps.start.getTime()
        return true
      } else {
        return false
      }
    }
  },

  watch: {
    // Add a simple watch to trigger next automatically after some ms
    'form.selected': function (newVal, oldVal) {
      if (this.validInput(newVal)) {
        setTimeout(() => {
          this.next()
        }, 50)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin-top: 10px;
  margin-bottom: 60px;
}

.enlarge-icon {
  transform: scale(2);
}

.center-me {
  display: flex;
  flex: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 5px;
  font-size: 2.5em;
}

.card_thumbs-up {
  background: $color-emphasis-alt;
}
.card_thumbs-down {
  background: $color-emphasis;
}

.selected-box {
  animation: standOut 0.4s ease-in-out forwards;
}
.binary-box.trinary-box {
  margin-bottom: 1em;
  .icon {
    @include breakpoint($micro) {
      transform: scale(0.8);
    }
    @include breakpoint($tablet) {
      transform: scale(1.2);
    }
  }
}
</style>
